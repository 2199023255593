import React from "react";
import styled from "styled-components";
import logo from "./images/logo.png";

const Footer = styled.footer`
  background-color: #161e42;
  padding: 4rem 0;
  font-size: 1.4rem;
  @media (max-width: 56.25em) {
    padding: 8rem 0;
  }
`;

const LogoBox = styled.div`
  text-align: center;
  margin-bottom: 8rem;
  @media (max-width: 56.25em) {
    margin-bottom: 6rem;
  }
`;

const Logo = styled.img`
  width: 25rem;
  height: auto;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const FooterNavigation = styled.div`
  padding-top: 2rem;
  display: inline-block;
  @media (max-width: 56.25em) {
    width: 100%;
    text-align: center;
  }
`;

const FooterList = styled.ul`
  list-style: none;
  margin-bottom: 2rem;
`;

const FooterItem = styled.li`
  display: inline-block;
  outline: none;
  text-decoration: none;
  font-weight: 400;
  font-size: 2rem;
  color: #f6f6f6;
  &:not(:last-child) {
    margin-right: 3rem;
  }
  @media (max-width: 56.25em) {
    &:not(:last-child) {
      margin-right: 2rem;
    }
  }
`;

const FooterLink = styled.a`
  text-decoration: none;
  color: #f6f6f6;
  transition: all 0.12s ease;
  opacity: 70%;

  &:visited,
  &:focus {
    text-decoration: none;
    opacity: 100%;
  }

  &:hover {
    opacity: 100%;
  }
`;

const FooterCopyright = styled.p`
  border-top: 1px solid #777;
  padding-top: 2rem;
  width: 80%;
  text-align: center;
  font-weight: 200;
  line-height: 4rem;
  font-size: 1.5rem;
  opacity: 0.8;
  color: #f6f6f6;
  @media (max-width: 56.25em) {
    width: 100%;
    float: none;
  }
`;

export default class FooterSection extends React.Component {
  render() {
    return (
      <Footer>
        <LogoBox>
          <Logo src={logo} alt="Full logo" />
        </LogoBox>
        <Column>
          <Row>
            <FooterNavigation>
              <FooterList>
                <FooterItem>
                  <FooterLink href="#home">Home</FooterLink>
                </FooterItem>
                <FooterItem>
                  <FooterLink href="#services">Services</FooterLink>
                </FooterItem>
                <FooterItem>
                  <FooterLink href="#about">About us</FooterLink>
                </FooterItem>
                <FooterItem>
                  <FooterLink href="#contact">Contact Us</FooterLink>
                </FooterItem>
              </FooterList>
            </FooterNavigation>
          </Row>
          <Row>
            <FooterCopyright>
              Copyright &copy; by CodeBits. All rights are reserved to CodeBits.
            </FooterCopyright>
          </Row>
        </Column>
      </Footer>
    );
  }
}
