import React from "react";
import styled from "styled-components";
import Card from "../Card/Card";
import webdesign from "./images/computer_work.jpg";
import marketing from "./images/marketing.jpg";
import hardware from "./images/hardware.jpg";
import devops from "./images/devops.jpg";

const Container = styled.section`
  padding: 15rem 0 10rem 0;
  margin-top: -10rem;
`;

const HeadingContainer = styled.div`
  text-align: center;
  margin-bottom: 8rem;
`;

const HeadingText = styled.h2`
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  background-image: linear-gradient(to right, #98bcff, #0158f8);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  letter-spacing: 0.2rem;
  transition: all 0.2s;
  &:hover {
    /* transform: scale(1.1); */
    text-shadow: 0.5rem 1rem 2rem rgba(0, 0, 0, 0.2);
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: auto;
  @media (max-width: 56.25em) {
    flex-direction: column;
    overflow: hidden;
    align-items: center;
  }
`;

const packages = [
  {
    name: "Hardware Design",
    benefits: [
      "Circuit Design",
      "Circuit Testing",
      "Soldering & Packaging",
      "Installation",
    ],
    start_price: "$1000",
    image: hardware,
  },
  {
    name: "Web Design",
    benefits: ["UX Design", "Content Writing", "Web Development", "Hosting"],
    start_price: "$700",
    image: webdesign,
  },
  {
    name: "Marketing",
    benefits: ["SEO", "Facebook Advertising", "Google Advertising"],
    start_price: "$400",
    image: marketing,
  },
  {
    name: "DevOps",
    benefits: [
      "Cotinous Testing",
      "Continous Release and Deployment",
      "Collaborative Development",
      "Continous Monitoring",
    ],
    start_price: "$2000",
    image: devops,
  },
];

export default class Packages extends React.Component {
  render() {
    return (
      <Container className="section-donations" id="services">
        <HeadingContainer className="u-center-text u-margin-bottom-big">
          <HeadingText className="heading-secondary">Our Services</HeadingText>
        </HeadingContainer>
        <Row>
          {packages.map((pack) => (
            <Card {...pack} key={pack.name} />
          ))}
        </Row>
      </Container>
    );
  }
}
