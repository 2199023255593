import React from "react";
import styled from "styled-components";
import ContactForm from "../Form/Form";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 15rem 0 10rem 0;
  margin-top: -10rem;
  @media (max-width: 56.25em) {
    flex-direction: column;
    align-items: center;
  }
`;

const Row = styled.div`
  display: flex;
  width: 50%;
  @media (max-width: 56.25em) {
    width: 90%;
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 3rem;
  padding-left: 0;
`;

const Title = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 4rem;
  line-height: 5.8rem;
  color: #ffffff;
`;

const Description = styled.p`
  font-style: normal;
  font-weight: normal;
  font-weight: 200;
  line-height: 4rem;
  font-size: 1.5rem;
  color: #ffffff;
  opacity: 0.8;
`;

export default class Contact extends React.Component {
  render() {
    return (
      <Container id="contact">
        <Row>
          <Details>
            <Title>How Can We Help Your Business?</Title>
            <Description>
              CodeBits is located in Ntinda, Kampala, offering fully-fledged
              services for software development and engineering empowering
              effective near-shore management to its clients.
            </Description>
          </Details>
        </Row>
        <Row>
          <ContactForm />
        </Row>
      </Container>
    );
  }
}
