import styled from "styled-components";
import Contact from "./components/Contact/Contact";
import Header from "./components/Header/Header";
import Packages from "./components/Packages/Packages";
import AboutUs from "./components/AboutUsSection/AboutUs";
import TeamsSection from "./components/TeamsSection/TeamsSection";
import Footer from "./components/Footer/Footer";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
function App() {
  return (
    <Container>
      <Header />
      <Packages />
      <AboutUs />
      <Contact />
      <TeamsSection />
      <Footer />
    </Container>
  );
}

export default App;
