import React from "react";
import styled from "styled-components";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 3rem;
  border-radius: 0.3rem;
  overflow: hidden;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  background: linear-gradient(180deg, #0158f8 0%, #132558 81.25%, #161e42 100%);
`;

const FormLabel = styled.label`
  font-style: normal;
  font-weight: normal;
  font-size: 1.7rem;
  line-height: 2.6rem;
  color: #ffffff;
  margin-bottom: 0.5rem;
`;

const FormInput = styled.input`
  height: 50px;
  background: #161e42;
  border-radius: 1rem;
  border: none;
  padding-left: 2rem;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 26px;
  color: #ffffff;
  opacity: 0.7;
  margin-bottom: 1.5rem;
  &:focus {
    outline: none;
  }
`;

const FormTextArea = styled.textarea`
  height: 10rem;
  background: #161e42;
  border-radius: 10px;
  resize: none;
  border: none;
  margin-bottom: 2.5rem;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  padding: 2rem;

  color: #ffffff;

  opacity: 0.7;
  &:focus {
    outline: none;
  }
`;

const FormButton = styled.button`
  padding: 1.5rem 3.5rem;
  background: #ffffff;
  width: 40%;
  color: #0158f8;
  border-radius: 10px;
  font-weight: 600;
  font-size: 2rem;
  outline: none;
  border: none;
  cursor: pointer;
  &:active,
  &:focus {
    outline: none;
  }
`;

export default class ContactForm extends React.Component {
  render() {
    return (
      <Form>
        <FormLabel>Name</FormLabel>
        <FormInput />
        <FormLabel>Email</FormLabel>
        <FormInput />
        <FormLabel>Message</FormLabel>
        <FormTextArea />
        <FormButton>Send</FormButton>
      </Form>
    );
  }
}
