import React from "react";
import styled from "styled-components";
import NavBar from "../NavBar/NavBar";
import HeroSection from "../HeroSection/HeroSection";

const Container = styled.header`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
  font-family: "Poppins";
`;

const Header = () => {
  return (
    <Container id="home" data-testid="header">
      <NavBar />
      <HeroSection />
    </Container>
  );
};

export default Header;
