import React from "react";
import styled from "styled-components";
import { Btn } from "../NavBar/NavBar";
import hero from "./image/hero.png";
const Container = styled.div`
  height: 80vh;
  width: 100%;
  display: flex;
  @media (max-width: 56.25em) {
    flex-direction: column;
    align-items: center;
  }
`;
const ImageSection = styled.div`
  width: 50%;
  position: relative;
  @media (max-width: 56.25em) {
    display: none;
  }
`;

const DescriptionSection = styled.div`
  width: 50%;
  @media (max-width: 56.25em) {
    width: 90%;
  }
`;

const Heading = styled.h1`
  font-weight: 500;
  font-size: 6rem;
  margin-top: 3rem;
  margin-bottom: 2.5rem;
`;

const Vision = styled.p`
  font-weight: 200;
  line-height: 4rem;
  font-size: 1.5rem;
  margin-bottom: 3.5rem;
`;

const Image = styled.img`
  display: inline-block;
  position: absolute;
  top: -10%;
  left: -20%;
  width: 100%;
  object-fit: contain;
`;

const CTA = styled(Btn)`
  @media (max-width: 56.25em) {
    display: block;
  }
`;

const HeroSection = () => {
  return (
    <Container>
      <ImageSection>
        <Image src={hero} alt="web design" />
      </ImageSection>

      <DescriptionSection>
        <Heading>Digital Service With Excellent Quality</Heading>
        <Vision>
          Our emphasis is on offering as highh degree of product user
          friendliness through a positive, creative and focused company staff.
        </Vision>
        <CTA>Get Started</CTA>
      </DescriptionSection>
    </Container>
  );
};

export default HeroSection;
