import React from "react";
import styled from "styled-components";
import { Btn } from "../NavBar/NavBar";
import work from "./images/working.jpg";
import svg from "./images/background.svg";

const Container = styled.div`
  width: 100%;
  height: 80rem;
  display: flex;
  position: relative;
  padding: 15rem 0 10rem 0;
  margin-top: -10rem;
  @media (max-width: 56.25em) {
    flex-direction: column;
    align-items: center;
  }
`;

const LeftSection = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding-right: 2rem;
  @media (max-width: 56.25em) {
    width: 90%;
  }
`;

const RightSection = styled.div`
  width: 60%;
  height: 100%;
  position: relative;
  z-index: 1;
  @media (max-width: 56.25em) {
    display: none;
  }
`;

const Heading2 = styled.h3`
  font-size: 5.5rem;
  font-weight: 700;
  color: #ffffff;
`;

const BtnContainer = styled.div`
  display: flex;
`;

const Statement = styled.p`
  font-weight: 200;
  line-height: 4rem;
  font-size: 1.5rem;
  margin-bottom: 3.5rem;
  color: #ffffff;
`;

const TransparentBtn = styled.button`
  padding: 1.5rem 3.5rem;
  background: transparent;
  color: #f7f7f7;
  border-radius: 10px;
  font-weight: 600;
  font-size: 2rem;
  outline: none;
  border: none;
  cursor: pointer;
  border: 1px solid rgba(247, 247, 247, 0.1);
  box-sizing: border-box;
  border-radius: 1rem;
  margin-left: 2.5rem;
`;

const RightBox = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 21rem;
  padding: 4.2rem 6.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: rgba(7, 15, 39, 0.8);
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Email = styled.p`
  font-weight: 200;
  line-height: 4rem;
  font-size: 1.5rem;
  padding: 2rem;
  margin-bottom: 3.5rem;
  color: #ffffff;
  background-image: url(${svg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const LeftBox = styled.div`
  width: 50%;
  position: absolute;
  left: 0;
  bottom: 10rem;
  background: #161e42;
  border-radius: 1rem;
  z-index: 10;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  @media (max-width: 56.25em) {
    position: static;
    margin-top: 3rem;
    width: 90%;
  }
`;

const InnerBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  text-align: center;

  & p {
    color: #f6f6f6;
    opacity: 70%;
    font-weight: 700;
    font-size: 4.5rem;
  }
`;

const HireButton = styled(Btn)`
  @media (max-width: 56.25em) {
    display: block;
  }
`;

const AboutUs = () => {
  return (
    <Container id="about">
      <LeftSection>
        <Heading2>Why Us ?</Heading2>
        <Statement>
          CodeBits is an IT system integration, software, and hardware
          development company that works with Enterprise systems and companies.
        </Statement>
        <BtnContainer>
          <HireButton>Hire Us</HireButton>
          <TransparentBtn>Learn More</TransparentBtn>
        </BtnContainer>
      </LeftSection>
      <RightSection>
        <Image src={work} alt="people working" />
        <RightBox>
          <Email>infor@CodeBits.com</Email>
        </RightBox>
      </RightSection>
      <LeftBox>
        <InnerBox>
          <p>10</p>
          <p style={{ fontSize: "1.8rem" }}>Designers</p>
        </InnerBox>

        <InnerBox>
          <p>3</p>
          <p style={{ fontSize: "1.8rem" }}>Back End Engineers</p>
        </InnerBox>

        <InnerBox>
          <p>4</p>
          <p style={{ fontSize: "1.8rem" }}>Marketeers</p>
        </InnerBox>

        <InnerBox>
          <p>7</p>
          <p style={{ fontSize: "1.8rem" }}>Front End Developers</p>
        </InnerBox>
      </LeftBox>
    </Container>
  );
};

export default AboutUs;
