import React from "react";
import styled from "styled-components";
import logo from "../Footer/images/logo.png";

const Container = styled.nav`
  position: relative;
  width: 100%;
  height: 20vh;
  font-family: "Poppins";
  font-weight: 400;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 2.5rem;
  align-items: center;
  @media (max-width: 56.25em) {
    grid-template-columns: repeat(1, 1fr);
    width: 90%;
    margin: 0 auto;
    justify-items: center;
  }
`;

const LogoBox = styled.div`
  padding: 2rem;
  background: transparent;
`;

const Logo = styled.img`
  width: 17rem;
  height: 100%;
  object-fit: contain;
  @media (max-width: 56.25em) {
    width: 20rem;
  }
`;

const ButtonContainer = styled.div`
  flex: 1;
  margin-left: auto;
`;

const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, max-content);
  grid-column-gap: 2.5rem;
  @media (max-width: 56.25em) {
    grid-column-gap: 2rem;
  }
`;

const ListItem = styled.li`
  list-style: none;
  outline: none;
  text-decoration: none;
  font-weight: 400;
  font-size: 2rem;
  color: #f6f6f6;
`;

const Link = styled.a`
  text-decoration: none;
  color: #f6f6f6;
  transition: all 0.12s ease;
  opacity: 70%;

  &:visited,
  &:focus {
    text-decoration: none;
    opacity: 100%;
  }

  &:hover {
    opacity: 100%;
  }
`;

export const Btn = styled.button`
  padding: 1.5rem 3.5rem;
  background: #ffffff;
  color: #0158f8;
  border-radius: 10px;
  /* font-family: sans-serif; */
  font-weight: 600;
  font-size: 2rem;
  outline: none;
  border: none;
  cursor: pointer;
  @media (max-width: 56.25em) {
    display: none;
  }
`;

const NavBar = () => {
  return (
    <Container data-testid="navbar">
      <LogoBox>
        <Logo src={logo} />
      </LogoBox>

      <List>
        <ListItem>
          <Link href="#home">Home</Link>
        </ListItem>
        <ListItem>
          <Link href="#services">Services</Link>
        </ListItem>
        <ListItem>
          <Link href="#about">About Us</Link>
        </ListItem>
        <ListItem>
          <Link href="#contact">Contact Us</Link>
        </ListItem>
      </List>

      <ButtonContainer>
        <Btn>Let's Talk</Btn>
      </ButtonContainer>
    </Container>
  );
};

export default NavBar;
