import React from "react";
import styled from "styled-components";

const CardContainer = styled.div`
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  height: 52rem;
  min-width: 35rem;
  max-width: 100%;
  overflow: hidden;
  &:hover .card__side--front {
    transform: rotateY(-180deg);
  }
  &:hover .card__side--back {
    transform: rotateY(0);
  }

  &:not(:last-child) {
    margin-right: 5rem;
  }

  @media (max-width: 56.25em) {
    width: 90%;
    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 3rem;
    }
  }
`;

const CardSide = styled.div`
  height: 52rem;
  transition: all 0.8s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  backface-visibility: hidden;
  border-radius: 0.3rem;
  overflow: hidden;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
`;

const CardFront = styled(CardSide)`
  background-color: #161e42;
`;

const CardBack = styled(CardSide)`
  background: linear-gradient(180deg, #0158f8 0%, #132558 81.25%, #161e42 100%);
  transform: rotateY(-180deg);
`;

const CardPicture = styled.div`
  background-size: cover;
  height: 23rem;
  background-blend-mode: screen;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  background-image: linear-gradient(to right bottom, #98bcff, #0158f8),
    url(${(props) => props.image});
`;

const CardHeading = styled.h4`
  font-size: 2.8rem;
  font-weight: 300;
  text-transform: uppercase;
  color: #fff;
  position: absolute;
  top: 12rem;
  right: 2rem;
  text-align: right;
  width: 75%;
`;

const CardHeadingSpan = styled.span`
  padding: 1rem 1.5rem;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-image: linear-gradient(
    to right bottom,
    rgba(152, 188, 255, 0.85),
    rgba(1, 88, 248, 0.85)
  );
`;

const CardDetails = styled.div`
  ul {
    list-style: none;
    width: 80%;
    margin: 0 auto;
    color: #f6f6f6;
  }

  ul li {
    text-align: center;
    font-size: 1.5rem;
    padding: 1rem;
  }
  ul li:not(:last-child) {
    border-bottom: 0.1rem solid #eee;
  }
`;

const CardCTA = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  text-align: center;
`;

const CardPriceBox = styled.div`
  text-align: center;
  color: #fff;
  margin-bottom: 8rem;
`;

const CardPriceStart = styled.p`
  font-size: 1.4rem;
  text-transform: uppercase;
`;

const CardPriceValue = styled.p`
  font-size: 6rem;
  font-weight: 100;
`;

const Button = styled.button`
  text-transform: uppercase;
  text-decoration: none;
  padding: 1.5rem 4rem;
  display: inline-block;
  border-radius: 10rem;
  transition: all 0.2s;
  position: relative;
  font-size: 1.6rem;
  border: none;
  background-color: #070f27;
  color: #f6f6f6;
  &:hover {
    transform: translateY(-0.2rem);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  }
  &:active,
  &:focus {
    outline: none;
    transform: translateY(-0.1rem);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  }
`;

export default class Card extends React.Component {
  render() {
    const { name, benefits, start_price, image } = this.props;
    return (
      <CardContainer>
        <CardFront className="card__side--front">
          <CardPicture image={image} className="card__picture--1">
            &nbsp;
          </CardPicture>
          <CardHeading className="card__heading">
            <CardHeadingSpan className="card__heading-span card__heading-span--1">
              {name}
            </CardHeadingSpan>
          </CardHeading>
          <CardDetails className="card__details">
            <ul>
              {benefits.map((benefit) => (
                <li key={benefit}>{benefit}</li>
              ))}
            </ul>
          </CardDetails>
        </CardFront>
        <CardBack className="card__side card__side--back card__side--back-1">
          <CardCTA className="card__cta">
            <CardPriceBox className="card__price-box">
              <CardPriceStart className="card__price-start">
                Starting from
              </CardPriceStart>
              <CardPriceValue className="card__price-value">
                {start_price}
              </CardPriceValue>
            </CardPriceBox>
            <Button className="btn btn--white">Hire us</Button>
          </CardCTA>
        </CardBack>
      </CardContainer>
    );
  }
}

/**
 * <li>UI Design</li>
              <li>UX Design</li>
              <li>Content Writing</li>
              <li>Web Development</li>
              <li>Hosting</li>
 */
