import React from "react";
import styled from "styled-components";
import dan from "./images/dan.jpg";
import nick from "./images/nickson.jpg";
import fidel from "./images/fedele.jpg";
import kiragu from "./images/kiragu.jpg";
import marosa from "./images/marosa.jpg";
import majaliwa from "./images/majaliwa.jpg";

const Main = styled.main`
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 10rem;
`;

const Section = styled.section`
  display: block;
  width: 100%;
`;

const CardsContainer = styled.div`
  padding-bottom: 0.5rem;
  width: 100%;
`;

const OuterContainer = styled.div`
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 10rem;
  padding-bottom: 1.5rem;
  text-align: center;
`;

const InnerContainer = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
`;

const HeadingContainer = styled.div`
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  background-image: linear-gradient(to right, #98bcff, #0158f8);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-letter-spacing: 0.2rem;
  -moz-letter-spacing: 0.2rem;
  -ms-letter-spacing: 0.2rem;
  letter-spacing: 0.2rem;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
`;
const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 6rem;
  justify-items: center;
  align-items: center;
  margin-top: 2.5rem;
`;

const OuterCardContainer = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 3rem;
  margin-bottom: 1.5rem;
`;

const Card = styled.div`
  border-radius: 20px;

  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  position: relative;
  display: -ms-flexbox;
  display: flex;
  /* -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0; */
  word-wrap: break-word;
  background-color: #161e42;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
`;

const CardBody = styled.div`
  padding: 1.25rem;
`;

const Image = styled.img`
  background: #f6f6f6;
  padding: 0.5rem;
  object-fit: cover;
  border-radius: 50%;
  width: 100%;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 0.125rem 0.25rem rgba(7, 15, 39, 0.8);

  width: 15rem;
  height: 15rem;
  overflow: hidden;
  position: absolute;
  top: -20%;
  right: 6%;
  margin-bottom: 1.5rem;
  float: right;
`;

const ContentBox = styled.div`
  height: 30rem;
  padding: 1.25rem;
`;

const Name = styled.h3`
  font-weight: 800;
  font-size: 2.5rem;
  margin-right: 15rem;
  text-transform: capitalize;
  margin-bottom: 0.5rem;
  line-height: 1.6;
  color: #f6f6f6;
  text-align: left;
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 1.6;
  color: #ffffff;
  text-transform: capitalize;
  display: block;
  margin-top: 0;
  text-align: left;
`;

const StoryLine = styled.p`
  font-weight: 400;
  font-size: 1.5rem;
  color: #ffffff;
  margin-top: 4rem;
  text-align: left;
  line-height: 2;
`;

const TeamsSection = () => {
  return (
    <Main id="teams">
      <Section>
        <CardsContainer>
          <OuterContainer>
            <InnerContainer>
              <HeadingContainer>Our Team</HeadingContainer>
            </InnerContainer>
          </OuterContainer>

          <Container>
            <OuterCardContainer>
              <Card>
                <CardBody>
                  <ImageContainer>
                    <Image src={nick} alt="nickson" />
                  </ImageContainer>

                  <ContentBox>
                    <Name>Mungujakisa Nickson</Name>
                    <Description>- C.E.O</Description>
                    <StoryLine>
                      {" "}
                      In case you don't read Twitter, the news, or just can't
                      get enough of The Apprentice host's legendary oration, try
                      this Trump lorem ipsum generator on for size.
                    </StoryLine>
                  </ContentBox>
                </CardBody>
              </Card>
            </OuterCardContainer>

            <OuterCardContainer>
              <Card>
                <CardBody>
                  <ImageContainer>
                    <Image src={dan} alt="daniel" />
                  </ImageContainer>
                  <ContentBox>
                    <Name>Ssejjemba Daniel</Name>
                    <Description>- Lead Front End developer</Description>
                    <StoryLine>
                      {" "}
                      If you haven't seen Game of Thrones, go watch it right
                      now. If you have then you'll totally get why this Hodor
                      themed lorem ipsum generator is just brilliant.
                    </StoryLine>
                  </ContentBox>
                </CardBody>
              </Card>
            </OuterCardContainer>

            <OuterCardContainer>
              <Card>
                <CardBody>
                  <ImageContainer>
                    <Image src={fidel} alt="fidel" />
                  </ImageContainer>
                  <ContentBox>
                    <Name>Amuge Dorothy</Name>
                    <Description>- Lead Back End developer</Description>
                    <StoryLine>
                      A handcrafted, small-batch, artisinal pour-over version of
                      the classic lorem ipsum generator, Hipster Ipsum will give
                      your mocks that blue collar touch.
                    </StoryLine>
                  </ContentBox>
                </CardBody>
              </Card>
            </OuterCardContainer>

            <OuterCardContainer>
              <Card>
                <CardBody>
                  <ImageContainer>
                    <Image src={kiragu} alt="kiragu" />
                  </ImageContainer>
                  <ContentBox>
                    <Name>Nampongo Christine</Name>
                    <Description>- Lead Designer</Description>
                    <StoryLine>
                      Raise your design from the dead with an army of Zombie
                      Ipsum, frightful filler text that just won't die. Try the
                      lorem ipsum of the undead if you dare...
                    </StoryLine>
                  </ContentBox>
                </CardBody>
              </Card>
            </OuterCardContainer>

            <OuterCardContainer>
              <Card>
                <CardBody>
                  <ImageContainer>
                    <Image src={marosa} alt="Marosa" />
                  </ImageContainer>
                  <ContentBox>
                    <Name>Akulu Flavia</Name>
                    <Description>- Computer Technician</Description>
                    <StoryLine>
                      Like your lorem ipsum extra crispy? Then Bacon Ipsum is
                      the placeholder text generator for you. Side of eggs and
                      hashbrowns is optional, but recommended.
                    </StoryLine>
                  </ContentBox>
                </CardBody>
              </Card>
            </OuterCardContainer>

            <OuterCardContainer>
              <Card>
                <CardBody>
                  <ImageContainer>
                    <Image src={majaliwa} alt="majaliwa" />
                  </ImageContainer>

                  <ContentBox>
                    <Name>Tumukunde Henry</Name>
                    <Description>- Web Designer</Description>
                    <StoryLine>
                      Sugary sweet lorem ipsum? You got it with Cupcake Ipsum,
                      the only text generator that includes marshmallows, carrot
                      cake, and perhaps even a cherry on top.
                    </StoryLine>
                  </ContentBox>
                </CardBody>
              </Card>
            </OuterCardContainer>
          </Container>
        </CardsContainer>
      </Section>
    </Main>
  );
};

export default TeamsSection;
